import React, {useEffect, useState} from "react";
import {Button, IconButton, Zoom} from "@mui/material";
import {ArrowUpward} from "@mui/icons-material";

const BackToTopButton = () => {
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleScroll = () => {
        if (window.scrollY > 200) {
            setShowButton(true);
        } else {
            setShowButton(false);
        }
    };

    return (
        <Zoom in={showButton} >
            <IconButton
                variant="contained"
                onClick={() => window.scrollTo(0, 0)}
                style={{
                    position: "fixed",
                    color: "white",
                    bottom: "20px",
                    right: "20px",
                    display: showButton ? "flex" : "none",
                    backgroundColor: "#3f51b5",
                    zIndex: 1000,
                }}
            >
                <ArrowUpward />
            </IconButton>
        </Zoom>
    );
};

export default BackToTopButton;
import styles from '../../styles/Footer.module.scss'
import PageContainer from "../layouts/PageContainer";
import {Box, Typography} from "@mui/material";
import Link from "next/link";
import companyInfo from "../../constants/address.json";
import SocialNetworks from "../shared/SocialNetworks";
import LetsGetStarted from "../shared/LetsGetStarted";
import Logo from "../shared/Logo";


export default function Footer() {
    return (
        <footer className={styles.footerContainer}>
            <PageContainer>
                <LetsGetStarted/>
                <Box className={styles.footerInnerContainer}>
                    <Box className={styles.footerInnerTop}>
                        <Box className={styles.socials}>
                            <Logo variant={"white"} size={"large"}/>

                            <SocialNetworks/>
                        </Box>

                        <Box className={styles.footerItem}>
                            <Typography variant={"h6"} component={"h6"} className={styles.footerItemHeader}>
                                Links
                            </Typography>
                            <Link href={"/about"} className={styles.footerItemDetails}>About Us</Link>
                            <Link href={"/press-releases"} className={styles.footerItemDetails}>Press Releases</Link>
                            <Link href={"/page/terms-of-service"} className={styles.footerItemDetails}>Terms of
                                Service</Link>
                            <Link href={"/page/privacy-policy"} className={styles.footerItemDetails}>Privacy
                                Policy</Link>
                        </Box>

                        <Box className={styles.footerItem}>
                            <Typography variant={"h6"} component={"h6"} className={styles.footerItemHeader}>Get in
                                touch</Typography>
                            <Typography variant={"body1"}
                                        className={styles.footerItemDetails}>{companyInfo["company"]}</Typography>
                            <Typography variant={"body1"}
                                        className={styles.footerItemDetails}>{companyInfo["city"]}</Typography>
                            <Typography variant={"body1"}
                                        className={styles.footerItemDetails}>{companyInfo["street"]}</Typography>
                            <Typography variant={"body1"} className={styles.footerItemDetails}><Link
                                href={"tel:" + companyInfo["phone"]}>{companyInfo["phone"]}</Link></Typography>
                            <Typography variant={"body1"} className={styles.footerItemDetails}><Link target={"_blank"}
                                                                                                     href={"mailto:" + companyInfo["email"]}>{companyInfo["email"]}</Link></Typography>
                        </Box>
                    </Box>
                </Box>

                <Box className={styles.footerCopyRights}>
                    {new Date().getFullYear()} © All rights reserved. Powered by XOP Networks
                </Box>
            </PageContainer>
        </footer>
    )
}

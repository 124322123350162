import {
    AppBar,
    Avatar,
    Box,
    Button,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material";
import PageContainer from "../layouts/PageContainer";
import Link from "next/link";
import styles from '../../styles/Header.module.css'
import Logo from "../shared/Logo";
import {useState, useEffect} from "react";
import clsx from "clsx";
import {useRouter} from "next/router";
import MenuIcon from '@mui/icons-material/Menu';

export default function NavigationBar() {
    const router = useRouter();

    // increase elevation on scroll
    const [elevation, setElevation] = useState((router.pathname === '/') ? 0 : 4);

    const [anchorElUser, setAnchorElUser] = useState(null);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 100) {
            setElevation(4);
        } else {
            setElevation(0);
        }
    }

    useEffect(() => {
        if (router.pathname === '/') {
            setElevation(0);
            window.addEventListener('scroll', handleScroll)
        } else {
            setElevation(4);
            window.removeEventListener('scroll', handleScroll)
        }

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [router.pathname])

    return (
        <AppBar className={clsx(styles.appBar, elevation !== 0 && styles.appBarElevated)} position="sticky"
                elevation={elevation}>
            <Toolbar className={styles.toolbar}>
                <PageContainer>
                    <Box display={"flex"} flexDirection={"row"}>
                        <Logo size={"large"}/>

                        <Box className={styles.authMenu}>
                            <nav className={styles.headerNav}>
                                <Button component={Link} href={'/'} color="inherit" variant={"text"}>
                                    Home
                                </Button>
                                <Button component={Link} href={'/about'} title={'About HootHoller'} color="inherit" variant={"text"}>
                                    About
                                </Button>
                                <Button component={Link} href={'/service/how-it-works'} title={'HootHoller Service'} color="inherit" variant={"text"}>
                                    Service
                                </Button>
                                <Button component={Link} href={'/pricing'} color="inherit" variant={"text"}>
                                    Pricing
                                </Button>
                                <Button component={Link} href={'/contact'} color="inherit"variant={"text"}>
                                    Contact
                                </Button>
                                <Button component={Link} href={'/support'} color="inherit" variant={"text"}>
                                    Support
                                </Button>
                                <Button component={Link} href={'/press-releases'} color="inherit" variant={"text"}>
                                    Press
                                </Button>
                            </nav>

                            {/*<Button component={Link} href={'/signup'} color="inherit" variant={"outlined"}*/}
                            {/*        className={styles.signupBtn}>Sign Up</Button>*/}
                            {/*<Button component={Link} href={settings.targetServer} target={"_blank"}*/}
                            {/*        color="inherit" variant={"contained"} className={styles.loginBtn}>Log In</Button>*/}
                        </Box>

                        <Box className={styles.authMobileMenu}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                                    <MenuIcon/>
                                </IconButton>
                            </Tooltip>
                            <Menu
                                className={styles.authMobileMenuInner}
                                keepMounted
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                <MenuItem key={1} onClick={handleCloseUserMenu}>
                                    <Button component={Link} href={'/'} color="inherit" variant={"text"}>Home</Button>
                                </MenuItem>
                                <MenuItem key={2} onClick={handleCloseUserMenu}>
                                    <Button component={Link} href={'/about'} color="inherit" variant={"text"}>About</Button>
                                </MenuItem>
                                <MenuItem key={2} onClick={handleCloseUserMenu}>
                                    <Button component={Link} href={'/service/how-it-works'} color="inherit" variant={"text"}>Service</Button>
                                </MenuItem>
                                <MenuItem key={3} onClick={handleCloseUserMenu}>
                                    <Button component={Link} href={'/pricing'} color="inherit" variant={"text"}>Pricing</Button>
                                </MenuItem>
                                <MenuItem key={4} onClick={handleCloseUserMenu}>
                                    <Button component={Link} href={'/contact'} color="inherit" variant={"text"}>Contact</Button>
                                </MenuItem>
                                <MenuItem key={5} onClick={handleCloseUserMenu}>
                                    <Button component={Link} href={'/support'} color="inherit" variant={"text"}>Support</Button>
                                </MenuItem>
                                <MenuItem key={5} onClick={handleCloseUserMenu}>
                                    <Button component={Link} href={'/press-releases'} color="inherit" variant={"text"}>Press</Button>
                                </MenuItem>
                                {/*<Divider orientation={"horizontal"}/>*/}
                                {/*<MenuItem key={6} onClick={handleCloseUserMenu}>*/}
                                {/*    <Button component={Link} href={'/signup'} color="inherit" variant={"text"}>Sign Up</Button>*/}
                                {/*</MenuItem>*/}
                                {/*<MenuItem key={7} onClick={handleCloseUserMenu}>*/}
                                {/*    <Button component={Link} href={settings.targetServer} color="inherit" variant={"text"}>Log In</Button>*/}
                                {/*</MenuItem>*/}
                            </Menu>
                        </Box>
                    </Box>
                </PageContainer>
            </Toolbar>
        </AppBar>
    )
}

import React from 'react';
import { Box } from "@mui/material";
import styles from '../../styles/Logo.module.css'
import Link from "next/link";

export default function Logo({ variant = 'white', size = 'medium' }) {
    const logoSrc = variant === 'white' ? '/images/logo-white.svg' : '/images/logo-default.svg'; // Assuming you have a default logo
    const logoStyle = {
        width: size === 'small' ? '50px' : size === 'large' ? '250px' : '100px', // Set sizes as per your requirement
        height: 'auto', // Maintains aspect ratio
    };

    return (
        <Box display={"flex"} flexDirection={"row"}>
            <Link href={'/'}>
                <img src={logoSrc} alt='IP-Based Hoot and Holler Service' className={styles.logo} style={logoStyle} />
            </Link>
        </Box>
    );
}

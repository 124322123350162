import {createTheme} from '@mui/material/styles';

const lightTheme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    width: '100%',
                    fontSize: '1.5rem',
                },
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '1.3rem',
                }
            }
        },

        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontSize: '1.2rem',
                }
            }
        }
    },
    palette: {
        mode: 'light',
        primary: {
            main: '#0E2A5A',
        }
    },
    shape: {
        borderRadius: 10,
    },

    typography: {
        fontFamily: 'Nunito Regular, sans-serif',
    }
});

export default lightTheme;

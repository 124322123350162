import {Box} from "@mui/material";
import styles from '../../styles/PageContainer.module.css'

export default function PageContainer({children}) {
    return (
        <Box className={styles.container}>
            {children}
        </Box>
    )
}

import React from 'react';
import styles from '../../styles/LetsGetStarted.module.css';
import Link from "next/link";

const LetsGetStarted = ({ linkUrl }) => { // Assume linkUrl is the prop for the link destination
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <h2>Let&apos;s Get Started</h2>
                <p>Ready to elevate your business? Connect with our experts and let&apos;s turn your vision into impactful results.</p>
            </div>
            <Link href="/contact" passHref>
                <button className={styles.button}>Contact Us Now</button>
            </Link>
        </div>
    );
};

export default LetsGetStarted;

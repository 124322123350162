import Link from 'next/link'
import Cookies from 'js-cookie'
import {MouseEvent, useCallback, useEffect, useState} from 'react'
import Box from "@mui/material/Box";
import styles from '../../styles/CookieConsent.module.css'
import PageContainer from "../layouts/PageContainer";
import {Button} from "@mui/material";

const USER_CONSENT_COOKIE_KEY = 'cookie_consent_is_true'
const USER_CONSENT_COOKIE_EXPIRE_DATE = 365

const CookieConsent = () => {
    const [cookieConsentIsTrue, setCookieConsentIsTrue] = useState(true)

    useEffect(() => {
        const consentIsTrue = Cookies.get(USER_CONSENT_COOKIE_KEY) === 'true'
        setCookieConsentIsTrue(consentIsTrue)
    }, [])

    const onClick = (e) => {
        e.preventDefault()

        if (!cookieConsentIsTrue) {
            Cookies.set(USER_CONSENT_COOKIE_KEY, 'true', {
                expires: USER_CONSENT_COOKIE_EXPIRE_DATE,
            })
            setCookieConsentIsTrue(true)
        }
    }

    if (cookieConsentIsTrue) {
        return null
    }

    return (
        <Box className={styles.container}>
                <Box className={styles.innerContainer}>
                    <Box>
                        <p>
                            This site uses services that use cookies to deliver better
                            experience and analyze traffic. <br /> You can learn more about the
                            services we use at our{' '}
                            <Link href="/page/privacy-policy">
                                privacy policy
                            </Link>
                            .
                        </p>
                    </Box>
                    <Box>
                        <Button
                            className={styles.button}
                            variant="contained"
                            size="large"
                            onClick={onClick}
                        >
                            Accept
                        </Button>
                    </Box>
                </Box>
        </Box>
    )
}

export default CookieConsent